import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import "../css/style.css";
import styles from "../styles/styles.js";
function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current route path

  const isActive = (path) => location.pathname === path; // Helper function

  return (
    <div className="container-fluid bg-light position-relative shadow">
      <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0 px-lg-5">
        <Link
          to="/"
          className="navbar-brand font-weight-bold text-secondary"
          style={{ fontSize: "50px" }}
        >
          <i className="flaticon-043-teddy-bear"></i>
          <span className="text-primary text-[40px]">Digi-Class</span>
        </Link>
        {/* Hamburger Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="block lg:hidden text-primary focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>

        {/* Menu */}
        <div
          className={`${
            isOpen ? "block mr-auto" : "hidden"
          } lg:flex lg:items-center lg:space-x-6 ml-auto`}
        >
          <Link
            to="/"
            className={`block py-2 text-xl ${
              isActive("/")
                ? `${styles.primary_text}`
                : `${styles.secondary_text}`
            } hover:text-primary`}
          >
            Accueil
          </Link>
          <Link
            to="/niveaux"
            className={`block py-2 text-xl ${
              isActive("/niveaux")
                ? `${styles.primary_text}`
                : `${styles.secondary_text}`
            } hover:text-primary`}
          >
            Niveaux
          </Link>
          <Link
            to="/blog"
            className={`block py-2 text-xl ${
              isActive("/blog")
                ? `${styles.primary_text}`
                : `${styles.secondary_text}`
            } hover:text-primary`}
          >
            Blog
          </Link>
          <Link
            to="/contact"
            className={`block py-2 text-xl ${
              isActive("/contact")
                ? `${styles.primary_text}`
                : `${styles.secondary_text}`
            } hover:text-primary`}
          >
            Contact
          </Link>
          <a
            href="/login"
            className="block py-2 px-4 mt-2 lg:mt-0 lg:ml-4 text-center text-white bg-primary rounded-lg hover:bg-primary-light"
          >
            Nous rejoindre
          </a>
        </div>
      </nav>
    </div>
  );
}

export default Header;
