import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const DocumentDetail = () => {
  const { documentId } = useParams();
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!documentId) {
        console.error('Invalid documentId');
        return;
      }

      setLoading(true);

      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/documents/${documentId}`);
        const data = response.data;

        if (data && data.title) {
          setDocument(data);
        } else {
          console.error('Invalid data structure received from the server.');
        }
      } catch (error) {
        console.error('Error fetching document data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [documentId]);

  const handleDownload = async () => {
    if (!document || !document.filePath) {
      console.error('Invalid document or fileName.');
      return;
    }

    const pdfUrl = `../../server/uploads/${document.filePath}`;
    console.log(pdfUrl);
      var filePath = document.filePath;

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = filePath; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);


    // using Java Script method to get PDF file
    fetch(pdfUrl).then((response) => {
      response.blob().then((blob) => {

        document.addEventListener('DOMContentLoaded', function() {
          // Your code here
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "your-desired-filename.pdf";
          alink.click();
      });
      });
    });
  }

  return (
    <div className="container-fluid bg-primary">
      {/* Include the header content here */}
      {/* You can reuse the header component if you've created one */}

      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-9">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <h1>{document && document.title}</h1>
                <p>Meta Title: {document && document.metaTitle}</p>
                <p>Meta Description: {document && document.metaDescription}</p>
                <p>Education Level: {document && document.education_level}</p>
                <p>Class: {document && document.classe}</p>
                <p>Image</p>
                <p>Class: {document && document.filePath}</p>

                <img width={100} height={100} src={document && document.imagePath} alt="Document Image" />
                {/* Add more details as needed */}

                <a
      
      href={`/documents/${document.filePath}`}
      download={document.filePath}
      target="_blank"
      rel="noreferrer"
    >

    
                <button  className="btn btn-primary">
                  Download
                </button>
                </a>
              </>
            )}
          </div>
          <div className="col-lg-3">
            {/* Include any additional content or sidebar here */}
          </div>
        </div>
      </div>

      {/* Include the footer component here */}
    </div>
  );
};

export default DocumentDetail;
