import React from 'react';
import imgDetail from '../img/blog1.jpg'; // Replace 'img/detail.jpg' with the correct path
import imgBlog1 from '../img/imgBlog1.jpg'; // Replace 'img/blog-1.jpg' with the correct path
import imgBlog2 from '../img/imgBlog2.png'; // Replace 'img/blog-2.jpg' with the correct path
import imgUser from '../img/user.jpg'; // Replace 'img/user.jpg' with the correct path
import imgPost1 from '../img/post-1.jpg'; // Replace 'img/post-1.jpg' with the correct path
import imgPost2 from '../img/post-2.jpg'; // Replace 'img/post-2.jpg' with the correct path
import imgPost3 from '../img/post-3.jpg'; // Replace 'img/post-3.jpg' with the correct path
import imgBlog3 from '../img/blog-3.jpg'; // Replace 'img/blog-3.jpg' with the correct path

const ArticleBlog2 = () => {
  return (
    <>
      {/* Header Start */}
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: '400px' }}
        >
          <h3 className="display-3 font-weight-bold text-white">Blog Detail</h3>
          <div className="d-inline-flex text-white">
            <p className="m-0"><a className="text-white" href="">Home</a></p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Blog Detail</p>
          </div>
        </div>
      </div>
      {/* Header End */}

      {/* Detail Start */}
      <div className="container py-5">
        <div className="row pt-5">
          <div className="col-lg-8">
            <div className="d-flex flex-column text-left mb-3">
              <p className="section-title pr-5">
                <span className="pr-2">Blog</span>
              </p>
              <h1 className="mb-3">La Révolution de l'Apprentissage des Langues en Ligne</h1>
              <div className="d-flex">
                <p className="mr-3"><i className="fa fa-user text-primary"></i> Admin</p>
                <p className="mr-3">
                  <i className="fa fa-folder text-primary"></i> Education
                </p>
                <p className="mr-3"><i className="fa fa-comments text-primary"></i> 15</p>
              </div>
            </div>
            <div className="mb-5">
              <img
                className="img-fluid rounded w-100 mb-4"
                src={imgBlog2}
                alt="Image"
              />
              <p>
                <strong>La Révolution de l'Apprentissage des Langues en Ligne</strong> ouvre de nouvelles portes passionnantes pour ceux qui aspirent à maîtriser une nouvelle langue. L'avènement de la technologie a radicalement transformé la manière dont nous abordons l'apprentissage des langues, offrant des opportunités flexibles et accessibles à tous. Dans cet article, explorons les aspects clés de cette révolution linguistique en ligne et comment elle a redéfini notre approche de l'acquisition des langues.
              </p>
              <p>
                <strong>Flexibilité et Accessibilité :</strong> L'une des principales caractéristiques de la révolution de l'apprentissage des langues en ligne est la flexibilité qu'elle offre aux apprenants. Les cours en ligne permettent d'étudier à son propre rythme, de n'importe où dans le monde, et souvent selon son propre emploi du temps. Cette accessibilité accrue élimine les barrières géographiques et temporelles, offrant une expérience d'apprentissage plus adaptée à chacun.
              </p>
              <p>
                <strong>Interactivité et Immersion Virtuelle :</strong> Les plateformes d'apprentissage en ligne intègrent des fonctionnalités interactives et des outils innovants qui créent une expérience d'immersion virtuelle. Des sessions de conversation en direct aux exercices interactifs, les apprenants peuvent pratiquer la langue cible de manière immersive, renforçant ainsi leur compréhension et leur aisance dans la communication.
              </p>
              <p>
                <strong>Ressources Multimédias et Contenu Dynamique :</strong> La révolution de l'apprentissage des langues en ligne s'appuie sur des ressources multimédias riches et un contenu dynamique. Des vidéos pédagogiques aux podcasts en passant par les applications interactives, ces outils offrent une variété d'approches d'apprentissage qui correspondent aux différents styles d'apprenants, rendant le processus plus engageant et efficace.
              </p>
              <h2 className="mb-4">L'Intelligence Artificielle au Service de l'Apprentissage :</h2>
            
              <p>
                L'intégration de l'intelligence artificielle (IA) révolutionne également l'apprentissage des langues en ligne. Des chatbots conversationnels aux systèmes d'évaluation automatisés, l'IA offre des solutions personnalisées et un retour immédiat aux apprenants. Ces avancées permettent un suivi individualisé, identifiant les points faibles et adaptant les cours en conséquence.
              </p>
              <h3 className="mb-4">Communautés d'Apprentissage en Ligne :</h3>
              <img
                className="img-fluid rounded w-50 float-right ml-4 mb-3"
                src={imgBlog1}
                alt="Image"
              />
              <p>
                Les plateformes en ligne encouragent la création de communautés d'apprentissage, connectant des apprenants du monde entier. Ces communautés offrent des opportunités d'échange linguistique, de partage de ressources et de soutien mutuel. Les apprenants peuvent pratiquer la langue avec des locuteurs natifs et bénéficier d'une expérience culturelle authentique sans quitter leur domicile.
              </p>
              <p>
                En conclusion, la révolution de l'apprentissage des langues en ligne ouvre des horizons infinis pour les passionnés de langues. La combinaison de la flexibilité, de l'interactivité, des ressources multimédias, de l'IA et des communautés d'apprentissage crée une expérience d'apprentissage riche et diversifiée. Que vous souhaitiez apprendre une nouvelle langue par passion, pour le travail ou pour voyager, les opportunités en ligne sont à portée de clic. Plongez dans cette révolution et explorez les langues avec enthousiasme et confiance !
              </p>
            </div>



            {/* Related Post */}
            {/* <div className="mb-5 mx-n3">
              <h2 className="mb-4 ml-3">Autres articles</h2>
              <div className="owl-carousel post-carousel position-relative">
                <div
                  className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mx-3"
                >
                  <img
                    className="img-fluid"
                    src={imgPost1}
                    style={{ width: '80px', height: '80px' }}
                    alt="Image"
                  />
                  <div className="pl-3">
                    <h5 className="">Diam amet eos at no eos</h5>
                    <div className="d-flex">
                      <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web
                        Design</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                      >
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mx-3"
                >
                  <img
                    className="img-fluid"
                    src={imgPost3}
                    style={{ width: '80px', height: '80px' }}
                    alt="Image"
                  />
                  <div className="pl-3">
                    <h5 className="">Diam amet eos at no eos</h5>
                    <div className="d-flex">
                      <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web
                        Design</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Comment List */}
            {/* <div className="mb-5">
              <h2 className="mb-4">3 Comments</h2>
              <div className="media mb-4">
                <img
                  src={imgUser}
                  alt="Image"
                  className="img-fluid rounded-circle mr-3 mt-1"
                  style={{ width: '45px' }}
                />
                <div className="media-body">
                  <h6>
                    Firas <small><i>01 Jan 2023</i></small>
                  </h6>
                  <p>
                  Enseignant D'Anglais.
                  </p>
                  <button className="btn btn-sm btn-light">Reply</button>
                </div>
              </div>
              <div className="media mb-4">
                <img
                  src={imgUser}
                  alt="Image"
                  className="img-fluid rounded-circle mr-3 mt-1"
                  style={{ width: '45px' }}
                />
                <div className="media-body">
                  <h6>
                    John Doe <small><i>01 Jan 2045 at 12:00pm</i></small>
                  </h6>
                  <p>
                    Diam amet duo labore stet elitr ea clita ipsum, tempor labore
                    accusam ipsum et no at. Kasd diam tempor rebum magna dolores
                    sed sed eirmod ipsum. Gubergren clita aliquyam consetetur
                    sadipscing, at tempor amet ipsum diam tempor consetetur at
                    sit.
                  </p>
                  <button className="btn btn-sm btn-light">Reply</button>
                  <div className="media mt-4">
                    <img
                      src={imgUser}
                      alt="Image"
                      className="img-fluid rounded-circle mr-3 mt-1"
                      style={{ width: '45px' }}
                    />
                    <div className="media-body">
                      <h6>
                        John Doe <small><i>01 Jan 2045 at 12:00pm</i></small>
                      </h6>
                      <p>
                        Diam amet duo labore stet elitr ea clita ipsum, tempor
                        labore accusam ipsum et no at. Kasd diam tempor rebum
                        magna dolores sed sed eirmod ipsum. Gubergren clita
                        aliquyam consetetur, at tempor amet ipsum diam tempor at
                        sit.
                      </p>
                      <button className="btn btn-sm btn-light">Reply</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Comment Form */}
            <div className="bg-light p-5">
              <h2 className="mb-4">Laisser un commentaire</h2>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Nom *</label>
                  <input type="text" className="form-control" id="name" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email *</label>
                  <input type="email" className="form-control" id="email" />
                </div>
              
                <div className="form-group">
                  <label htmlFor="message">Message *</label>
                  <textarea
                    id="message"
                    cols="30"
                    rows="5"
                    className="form-control"
                  ></textarea>
                </div>
                <div className="form-group mb-0">
                  <input
                    type="submit"
                    value="Envoyer"
                    className="btn btn-primary px-3"
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-4 mt-5 mt-lg-0">
            {/* Author Bio */}
            <div
              className="d-flex flex-column text-center bg-primary rounded mb-5 py-5 px-4"
            >
              <img
                src={imgUser}
                className="img-fluid rounded-circle mx-auto mb-3"
                style={{ width: '100px' }}
                alt="Author"
              />
              <h3 className="text-secondary mb-3">Firas</h3>
              <p className="text-white m-0">
                Enseignant D'Anglais.
              </p>
            </div>

            {/* Search Form */}
            <div className="mb-5">
              <form action="">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Rechercher"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-transparent text-primary"
                    ><i className="fa fa-search"></i
                    ></span>
                  </div>
                </div>
              </form>
            </div>

            {/* Category List
            <div className="mb-5">
              <h2 className="mb-4">Categories</h2>
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Web Design</a>
                  <span className="badge badge-primary badge-pill">150</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Web Development</a>
                  <span className="badge badge-primary badge-pill">131</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Online Marketing</a>
                  <span className="badge badge-primary badge-pill">78</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Keyword Research</a>
                  <span className="badge badge-primary badge-pill">56</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Email Marketing</a>
                  <span className="badge badge-primary badge-pill">98</span>
                </li>
              </ul>
            </div> */}



            {/* Recent Post */}
            {/* <div className="mb-5">
              <h2 className="mb-4">Recent Post</h2>
              <div
                className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mb-3"
              >
                <img
                  className="img-fluid"
                  src={imgPost1}
                  style={{ width: '80px', height: '80px' }}
                  alt="Image"
                />
                <div className="pl-3">
                  <h5 className="">Diam amet eos at no eos</h5>
                  <div className="d-flex">
                    <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web Design</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                    >
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mb-3"
              >
                <img
                  className="img-fluid"
                  src={imgPost2}
                  style={{ width: '80px', height: '80px' }}
                  alt="Image"
                />
                <div className="pl-3">
                  <h5 className="">Diam amet eos at no eos</h5>
                  <div className="d-flex">
                    <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web Design</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                    >
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mb-3"
              >
                <img
                  className="img-fluid"
                  src={imgPost1}
                  style={{ width: '80px', height: '80px' }}
                  alt="Image"
                />
                <div className="pl-3">
                  <h5 className="">Diam amet eos at no eos</h5>
                  <div className="d-flex">
                    <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web Design</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                    >
                  </div>
                </div>
              </div>
            </div> */}

            {/* Single Image */}
            <div className="mb-5">
              <img src={imgBlog2} alt="" className="img-fluid rounded" />
            </div>

            {/* Tag Cloud */}
            <div className="mb-5">
              <h2 className="mb-4">Mots clés</h2>
              <div className="d-flex flex-wrap m-n1">
                <a href="" className="btn btn-outline-primary m-1">Bac</a>
                <a href="" className="btn btn-outline-primary m-1">Education</a>
                <a href="" className="btn btn-outline-primary m-1">Information</a>
                <a href="" className="btn btn-outline-primary m-1">SEO</a>
                <a href="" className="btn btn-outline-primary m-1">Cours</a>
                <a href="" className="btn btn-outline-primary m-1">Examens</a>
                <a href="" className="btn btn-outline-primary m-1">Astuces</a>

              </div>
            </div>



            {/* Plain Text */}
            {/* <div>
              <h2 className="mb-4">Plain Text</h2>
              Aliquyam sed lorem stet diam dolor sed ut sit. Ut sanctus erat ea
              amet labore invidunt clita dolore amet, et erat eos lorem ipsum
              et erat stet eirmod sadipscing, erat diam amet erat at takimata
              takimata labore amet amet amet. Dolores erat diam kasd takimata,
              amet et dolores kasd. Et et et sit rebum diam. Et et sadipscing
              gubergren, rebum amet dolore nonumy voluptua et sit et kasd dolore
              ipsum. Gubergren sit sea invidunt dolores sed amet.
            </div> */}
          </div>
        </div>
      </div>
      {/* Detail End */}
    </>
  );
}
export default ArticleBlog2;
