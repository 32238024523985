import React from 'react';
import imgDetail from '../img/blog1.jpg'; // Replace 'img/detail.jpg' with the correct path
import imgBlog1 from '../img/blog-1.jpg'; // Replace 'img/blog-1.jpg' with the correct path
import imgBlog2 from '../img/blog-2.jpg'; // Replace 'img/blog-2.jpg' with the correct path
import imgUser from '../img/user.jpg'; // Replace 'img/user.jpg' with the correct path
import imgPost1 from '../img/post-1.jpg'; // Replace 'img/post-1.jpg' with the correct path
import imgPost2 from '../img/post-2.jpg'; // Replace 'img/post-2.jpg' with the correct path
import imgPost3 from '../img/post-3.jpg'; // Replace 'img/post-3.jpg' with the correct path
import imgBlog3 from '../img/blog-3.jpg'; // Replace 'img/blog-3.jpg' with the correct path

const ArticleBlog3 = () => {
  return (
    <>
      {/* Header Start */}
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: '400px' }}
        >
          <h3 className="display-3 font-weight-bold text-white">Blog Detail</h3>
          <div className="d-inline-flex text-white">
            <p className="m-0"><a className="text-white" href="">Home</a></p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Blog Detail</p>
          </div>
        </div>
      </div>
      {/* Header End */}

      {/* Detail Start */}
      <div className="container py-5">
        <div className="row pt-5">
          <div className="col-lg-8">
            <div className="d-flex flex-column text-left mb-3">
              <p className="section-title pr-5">
                <span className="pr-2">Blog</span>
              </p>
              <h1 className="mb-3">Réalité Virtuelle et Éducation en Ligne</h1>
              <div className="d-flex">
                <p className="mr-3"><i className="fa fa-user text-primary"></i> Admin</p>
                <p className="mr-3">
                  <i className="fa fa-folder text-primary"></i> Education
                </p>
                <p className="mr-3"><i className="fa fa-comments text-primary"></i> 15</p>
              </div>
            </div>
            <div className="mb-5">
              <img
                className="img-fluid rounded w-100 mb-4"
                src={imgDetail}
                alt="Image"
              />
              <p>
                <strong>Réalité Virtuelle et Éducation en Ligne</strong> : Une fusion innovante qui redéfinit l'expérience éducative. L'évolution rapide de la réalité virtuelle (RV) a ouvert de nouvelles avenues passionnantes dans le domaine de l'éducation en ligne. Dans cet article, plongeons dans l'univers de la réalité virtuelle et examinons comment elle transforme la manière dont nous apprenons et enseignons.
              </p>
              <p>
                <strong>Immersion Totale dans le Contenu :</strong> L'un des aspects les plus captivants de la réalité virtuelle est sa capacité à offrir une immersion totale dans le contenu éducatif. Les apprenants peuvent être transportés virtuellement dans des environnements historiques, des laboratoires scientifiques, ou même à travers des œuvres d'art, enrichissant ainsi l'apprentissage par une expérience visuelle et interactive inégalée.
              </p>
              <p>
                <strong>Séances Pratiques et Simulations :</strong> La réalité virtuelle permet la création de séances pratiques et de simulations réalistes. Que ce soit pour des cours de médecine, des formations professionnelles ou des simulations d'expériences scientifiques, les apprenants peuvent pratiquer et développer leurs compétences dans un environnement virtuel sécurisé et contrôlé.
              </p>
              <p>
                <strong>Accessibilité Mondiale :</strong> La RV transcende les barrières géographiques, offrant une éducation de qualité à des apprenants du monde entier. Les cours en réalité virtuelle peuvent être suivis depuis n'importe quel endroit, permettant une accessibilité mondiale à des ressources éducatives riches et variées.
              </p>
              <h2 className="mb-4">Collaboration Virtuelle et Apprentissage Social :</h2>

              <p>
                La réalité virtuelle facilite la collaboration virtuelle, permettant aux apprenants de travailler ensemble dans des espaces virtuels partagés. Que ce soit pour des projets de groupe, des discussions en temps réel, ou des simulations d'événements, la collaboration virtuelle renforce l'apprentissage social et favorise le développement de compétences interpersonnelles.
              </p>
              <h3 className="mb-4">Expériences Éducatives Personnalisées :</h3>

              <p>
                L'intégration de la réalité virtuelle permet la création d'expériences éducatives personnalisées. Les cours peuvent être adaptés aux besoins spécifiques de chaque apprenant, offrant ainsi un apprentissage individualisé et efficace. Cette personnalisation renforce l'engagement des apprenants et maximise leur compréhension des concepts.
              </p>
              <p>
                En conclusion, la réalité virtuelle révolutionne l'éducation en ligne en offrant une expérience d'apprentissage immersive et interactive. Des séances pratiques aux collaborations virtuelles, la RV ouvre de nouvelles possibilités éducatives passionnantes. En adoptant cette technologie novatrice, nous transformons l'éducation en ligne en une aventure captivante, préparant les apprenants du monde entier à un avenir où la réalité virtuelle et l'éducation se fondent harmonieusement.
              </p>
            </div>



            {/* Related Post */}
            {/* <div className="mb-5 mx-n3">
              <h2 className="mb-4 ml-3">Autres articles</h2>
              <div className="owl-carousel post-carousel position-relative">
                <div
                  className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mx-3"
                >
                  <img
                    className="img-fluid"
                    src={imgPost1}
                    style={{ width: '80px', height: '80px' }}
                    alt="Image"
                  />
                  <div className="pl-3">
                    <h5 className="">Diam amet eos at no eos</h5>
                    <div className="d-flex">
                      <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web
                        Design</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                      >
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mx-3"
                >
                  <img
                    className="img-fluid"
                    src={imgPost3}
                    style={{ width: '80px', height: '80px' }}
                    alt="Image"
                  />
                  <div className="pl-3">
                    <h5 className="">Diam amet eos at no eos</h5>
                    <div className="d-flex">
                      <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web
                        Design</small
                      >
                      <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Comment List */}
            {/* <div className="mb-5">
              <h2 className="mb-4">3 Comments</h2>
              <div className="media mb-4">
                <img
                  src={imgUser}
                  alt="Image"
                  className="img-fluid rounded-circle mr-3 mt-1"
                  style={{ width: '45px' }}
                />
                <div className="media-body">
                  <h6>
                    Firas <small><i>01 Jan 2023</i></small>
                  </h6>
                  <p>
                  Enseignant D'Anglais.
                  </p>
                  <button className="btn btn-sm btn-light">Reply</button>
                </div>
              </div>
              <div className="media mb-4">
                <img
                  src={imgUser}
                  alt="Image"
                  className="img-fluid rounded-circle mr-3 mt-1"
                  style={{ width: '45px' }}
                />
                <div className="media-body">
                  <h6>
                    John Doe <small><i>01 Jan 2045 at 12:00pm</i></small>
                  </h6>
                  <p>
                    Diam amet duo labore stet elitr ea clita ipsum, tempor labore
                    accusam ipsum et no at. Kasd diam tempor rebum magna dolores
                    sed sed eirmod ipsum. Gubergren clita aliquyam consetetur
                    sadipscing, at tempor amet ipsum diam tempor consetetur at
                    sit.
                  </p>
                  <button className="btn btn-sm btn-light">Reply</button>
                  <div className="media mt-4">
                    <img
                      src={imgUser}
                      alt="Image"
                      className="img-fluid rounded-circle mr-3 mt-1"
                      style={{ width: '45px' }}
                    />
                    <div className="media-body">
                      <h6>
                        John Doe <small><i>01 Jan 2045 at 12:00pm</i></small>
                      </h6>
                      <p>
                        Diam amet duo labore stet elitr ea clita ipsum, tempor
                        labore accusam ipsum et no at. Kasd diam tempor rebum
                        magna dolores sed sed eirmod ipsum. Gubergren clita
                        aliquyam consetetur, at tempor amet ipsum diam tempor at
                        sit.
                      </p>
                      <button className="btn btn-sm btn-light">Reply</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Comment Form */}
            <div className="bg-light p-5">
              <h2 className="mb-4">Laisser un commentaire</h2>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Nom *</label>
                  <input type="text" className="form-control" id="name" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email *</label>
                  <input type="email" className="form-control" id="email" />
                </div>
           
                <div className="form-group">
                  <label htmlFor="message">Message *</label>
                  <textarea
                    id="message"
                    cols="30"
                    rows="5"
                    className="form-control"
                  ></textarea>
                </div>
                <div className="form-group mb-0">
                  <input
                    type="submit"
                    value="Envoyer"
                    className="btn btn-primary px-3"
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-4 mt-5 mt-lg-0">
            {/* Author Bio */}
            <div
              className="d-flex flex-column text-center bg-primary rounded mb-5 py-5 px-4"
            >
              <img
                src={imgUser}
                className="img-fluid rounded-circle mx-auto mb-3"
                style={{ width: '100px' }}
                alt="Author"
              />
              <h3 className="text-secondary mb-3">Firas</h3>
              <p className="text-white m-0">
                Enseignant D'Anglais.
              </p>
            </div>

            {/* Search Form */}
            <div className="mb-5">
              <form action="">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Rechercher"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-transparent text-primary"
                    ><i className="fa fa-search"></i
                    ></span>
                  </div>
                </div>
              </form>
            </div>

            {/* Category List
            <div className="mb-5">
              <h2 className="mb-4">Categories</h2>
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Web Design</a>
                  <span className="badge badge-primary badge-pill">150</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Web Development</a>
                  <span className="badge badge-primary badge-pill">131</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Online Marketing</a>
                  <span className="badge badge-primary badge-pill">78</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Keyword Research</a>
                  <span className="badge badge-primary badge-pill">56</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center px-0"
                >
                  <a href="">Email Marketing</a>
                  <span className="badge badge-primary badge-pill">98</span>
                </li>
              </ul>
            </div> */}



            {/* Recent Post */}
            {/* <div className="mb-5">
              <h2 className="mb-4">Recent Post</h2>
              <div
                className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mb-3"
              >
                <img
                  className="img-fluid"
                  src={imgPost1}
                  style={{ width: '80px', height: '80px' }}
                  alt="Image"
                />
                <div className="pl-3">
                  <h5 className="">Diam amet eos at no eos</h5>
                  <div className="d-flex">
                    <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web Design</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                    >
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mb-3"
              >
                <img
                  className="img-fluid"
                  src={imgPost2}
                  style={{ width: '80px', height: '80px' }}
                  alt="Image"
                />
                <div className="pl-3">
                  <h5 className="">Diam amet eos at no eos</h5>
                  <div className="d-flex">
                    <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web Design</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                    >
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center bg-light shadow-sm rounded overflow-hidden mb-3"
              >
                <img
                  className="img-fluid"
                  src={imgPost1}
                  style={{ width: '80px', height: '80px' }}
                  alt="Image"
                />
                <div className="pl-3">
                  <h5 className="">Diam amet eos at no eos</h5>
                  <div className="d-flex">
                    <small className="mr-3"
                      ><i className="fa fa-user text-primary"></i> Admin</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-folder text-primary"></i> Web Design</small
                    >
                    <small className="mr-3"
                      ><i className="fa fa-comments text-primary"></i> 15</small
                    >
                  </div>
                </div>
              </div>
            </div> */}

            {/* Single Image */}
            <div className="mb-5">
              <img src={imgBlog2} alt="" className="img-fluid rounded" />
            </div>

            {/* Tag Cloud */}
            <div className="mb-5">
              <h2 className="mb-4">Mots clés</h2>
              <div className="d-flex flex-wrap m-n1">
                <a href="" className="btn btn-outline-primary m-1">Bac</a>
                <a href="" className="btn btn-outline-primary m-1">Education</a>
                <a href="" className="btn btn-outline-primary m-1">Information</a>
                <a href="" className="btn btn-outline-primary m-1">SEO</a>
                <a href="" className="btn btn-outline-primary m-1">Cours</a>
                <a href="" className="btn btn-outline-primary m-1">Examens</a>
                <a href="" className="btn btn-outline-primary m-1">Astuces</a>

              </div>
            </div>



            {/* Plain Text */}
            {/* <div>
              <h2 className="mb-4">Plain Text</h2>
              Aliquyam sed lorem stet diam dolor sed ut sit. Ut sanctus erat ea
              amet labore invidunt clita dolore amet, et erat eos lorem ipsum
              et erat stet eirmod sadipscing, erat diam amet erat at takimata
              takimata labore amet amet amet. Dolores erat diam kasd takimata,
              amet et dolores kasd. Et et et sit rebum diam. Et et sadipscing
              gubergren, rebum amet dolore nonumy voluptua et sit et kasd dolore
              ipsum. Gubergren sit sea invidunt dolores sed amet.
            </div> */}
          </div>
        </div>
      </div>
      {/* Detail End */}
    </>
  );
}
export default ArticleBlog3;
