const styles = {
  // custom_container: "w-11/12 hidden sm:block",
  // heading: 'text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]',
  // section: 'w-11/12 mx-auto',
  primary_text: "text-[#BA68C8] font-bold",
  secondary_text: "text-[#138496] font-bold",
  //   secondary_btn:
  // "text-white bg-[#00394f] border-[#00394f] hover:bg-[#001d29] hover:border-[#00141c] focus:bg-[#001d29] focus:border-[#00141c] focus:ring-2 focus:ring-[#26576980] disabled:bg-[#00394f] disabled:border-[#00394f] active:bg-[#00141c] active:border-[#000b0f]",
};

export default styles;
