import React from 'react';
import imgAbout1 from "../img/about-1.jpg";
import imgAbout2 from "../img/about-2.jpg";
import CaracsComponent from '../components/CaracsComponent';
function AboutPage() {
  return (
    <>
    <div className="container-fluid bg-primary mb-5">
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '400px' }}>
        <h3 className="display-3 font-weight-bold text-white">A propos</h3>
        <div className="d-inline-flex text-white">
          <p className="m-0"><a className="text-white" href="/">Accueil</a></p>
          <p className="m-0 px-2">/</p>
          <p className="m-0">A propos</p>
        </div>
      </div>
    </div>
      <div className="container-fluid py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <img className="img-fluid rounded mb-5 mb-lg-0" src={imgAbout1} alt="" />
          </div>
          <div className="col-lg-7">
            <p className="section-title pr-5">
              <span className="pr-2">Lire a propos de nous</span>
            </p>
            <h1 className="mb-4">La meilleure plateforme des cours en ligne</h1>
            <div className="row pt-2 pb-4">
              <div className="col-6 col-md-4">
                <img className="img-fluid rounded" src={imgAbout2} alt="" />
              </div>
              <div className="col-6 col-md-8">
                <ul className="list-inline m-0">
                  <li className="py-2 border-top border-bottom">
                    <i className="fa fa-check text-primary mr-3"></i>Cours
                  </li>
                  <li className="py-2 border-bottom">
                    <i className="fa fa-check text-primary mr-3"></i>Devoirs
                  </li>
                  <li className="py-2 border-bottom">
                    <i className="fa fa-check text-primary mr-3"></i>Séries d'exercices
                  </li>
                </ul>
              </div>
            </div>
            <a href="https://www.facebook.com/profile.php?id=100091218900992" className="btn btn-primary mt-2 py-2 px-4">Notre page Facebook</a>
          </div>
        </div>
      </div>
    </div>
    {/* <CaracsComponent/> */}
    </>
  );
}

export default AboutPage;
