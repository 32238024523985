import React, { useState } from 'react';
import LevelsComponent from '../components/LevelsComponent';
import { Link, useHistory } from 'react-router-dom';

// Import class images
import classImage1 from "../img/class-1.jpg";
import classImage2 from "../img/class-2.jpg";
import classImage3 from "../img/class-3.jpg";

const LevelSecondairePage = () => {
    // Array of class image paths
    const classImages = [classImage1, classImage2, classImage3];
    const history = useHistory();
    const [levelName, setLevelName] = useState('');
    const [className, setClassName] = useState('');
    const handleChooseClick = (index) => {
        //Exemple
        const level = 3;
        const docClass = index + 1;
        const newLevelName = 'Secondaire';
        const newClassName =`${index === 0 ? '1ère' : `${index + 1}ème`} année secondaire`;
        setLevelName(newLevelName);
        setClassName(newClassName);

        const path = `/documentsByLevelAndClasse/${level}-${docClass}`;
        history.push(path, { levelName: newLevelName, className: newClassName });
    };
    return (
        <>
            <div className="container-fluid bg-primary mb-5">
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: '400px' }}>
                    <h3 className="display-3 font-weight-bold text-white">Secondaire</h3>
                    <div className="d-inline-flex text-white">
                        <p className="m-0"><a className="text-white" href="/">Accueil</a></p>
                        <p className="m-0 px-2">/</p>
                        <p className="m-0">Secondaire</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {classImages.map((image, index) => (
                        <div key={index} className="col-md-4 mb-4">
                            <div className="card">
                                <img src={image} className="card-img-top" alt={`Class ${index + 1}`} />
                                <div className="card-body">
                                    <h5 className="card-title text-center mb-0">{`${index === 0 ? '1ère' : `${index + 1}ème`} année secondaire`}</h5>
                                    {/* Add other details as needed */}
                                </div>
                                <button className="btn btn-primary px-4 mx-auto mb-4" onClick={() => handleChooseClick(index)}>Choisir</button>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </>
    )
}

export default LevelSecondairePage;
