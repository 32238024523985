import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/Admin/Sidebar";

const DocumentForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    file: null,
    image: null,
    metaTitle: "",
    metaDescription: "",
    education_level: "primaire",
    docClass: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    const { name, type, files, value } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));

    // Debugging the file input change
    if (type === "file") {
      console.log(`${name} file selected:`, files[0]);
    }
  };

  const updateClasseOptions = () => {
    const educationLevel = document.getElementById("education_level").value;
    const classeSelect = document.getElementById("docClass");

    classeSelect.innerHTML = "";

    if (educationLevel == 1) {
      for (let i = 1; i <= 6; i++) {
        const option = document.createElement("option");
        option.value = `${i}`;
        option.text = `${i}ère année primaire`;
        classeSelect.appendChild(option);
      }
    } else if (educationLevel == 2) {
      for (let i = 7; i <= 9; i++) {
        const option = document.createElement("option");
        option.value = `${i}`;
        option.text = `${i}ème année de base`;
        classeSelect.appendChild(option);
      }
    } else if (educationLevel == 3) {
      for (let i = 1; i <= 4; i++) {
        const option = document.createElement("option");
        option.value = `${i}`;
        option.text = `${i}ère année secondaire`;
        classeSelect.appendChild(option);
      }
    } else if (educationLevel == 4) {
      // Display Bac options
      const bacOptions = [
        "Bac Sciences Expérimentales",
        "Bac Sciences de lInformatique",
        "Bac Mathématiques",
        "Bac Sciences Techniques",
        "Bac Economie et Gestion",
        "Bac Lettres",
      ];

      bacOptions.forEach((bacOption, index) => {
        const option = document.createElement("option");
        option.value = `${index + 1}`;
        option.text = bacOption;
        classeSelect.appendChild(option);
      });
    }
  };

  const convertToBase64 = (e) => {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: reader.result,
      }));
    };

    reader.onerror = (error) => {
      console.log("Error : ", error);
    };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("file", formData.file);
      formDataToSend.append("image", formData.image);
      Object.keys(formData)
        .filter((key) => key !== "file" && key !== "image")
        .forEach((key) => {
          formDataToSend.append(key, formData[key]);
        });
      // Convert FormData to an object and log it
      const formDataObject = {};
      formDataToSend.forEach((value, key) => {
        formDataObject[key] = value;
      });

      console.log(formDataObject);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/documents`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // if (response.status === 200) {
      setIsSuccess(true);
      console.log("Document created successfully:", response.data);
      alert("Document created successfully!");
      history.push("/admin/documents");
      // } else {
      //   console.error("Unexpected status code:", response.status);
      //   alert("Unexpected error occurred. Please try again.");
      // }
    } catch (error) {
      console.error("Error creating document:", error);
      alert(
        "An unexpected error occurred. Please try again or contact the technical support team."
      );
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <Sidebar />
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full">
        <h2 className="text-2xl mb-4 text-center">Create a New Document</h2>
        {isSuccess && (
          <div className="mb-4 text-center text-green-500 font-bold">
            Document created successfully!
          </div>
        )}
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="title"
            >
              Title:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="title"
              name="title"
              type="text"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="file"
            >
              File Path:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="file"
              name="file"
              type="file"
              accept=".pdf, .doc, .docx"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="image"
            >
              Image Path:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="image"
              name="image"
              type="file"
              accept=".jpg, .png, .jpeg"
              onChange={convertToBase64}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="metaTitle"
            >
              Meta Title:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="metaTitle"
              name="metaTitle"
              type="text"
              value={formData.metaTitle}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="metaDescription"
            >
              Meta Description:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="metaDescription"
              name="metaDescription"
              type="text"
              value={formData.metaDescription}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="level"
            >
              Level:
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="education_level"
              name="education_level"
              required
              onChange={() => {
                handleChange({
                  target: {
                    name: "education_level",
                    value: document.getElementById("education_level").value,
                  },
                });
                updateClasseOptions();
              }}
              value={formData.education_level}
            >
              <option value="1">Primaire</option>
              <option value="2">De base</option>
              <option value="3">Secondaire</option>
              <option value="4">Bac</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="docClass"
            >
              Classe:
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="docClass"
              name="docClass"
              onChange={handleChange}
              value={formData.docClass}
              required
            ></select>
          </div>
          <div className="text-center">
            <button
              className="bg-[#138496] hover-bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Document
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentForm;
