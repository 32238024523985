import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


const DocumentsByLevelAndClassPage = () => {
  const location = useLocation();
  console.log(location.state);
  const levelName = location.state && location.state.levelName ? location.state.levelName : '';
  const className = location.state && location.state.className ? location.state.className : '';
  console.log("levelName:", levelName);
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const documentsPerPage = 8;

  const { level, docClass } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/documents/by-level-and-class/${level}-${docClass}`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, [level, docClass]);

  if (!Array.isArray(documents) || documents.length === 0) {
    return (
      <>
        <div className="container-fluid bg-primary mb-5">
          <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '400px' }}>
            <h3 className="display-3 font-weight-bold text-white">{levelName}</h3>
            <div className="d-inline-flex text-white">
              <p className="m-0"><Link className="text-white" to="/">{levelName}</Link></p>
              <p className="m-0 px-2">/</p>
              <p className="m-0">{className}</p>
            </div>
          </div>
        </div>
        <div style={{ padding: '0 30px', textAlign: 'center' }}>
          <h4>Pas de documents pour le moment.</h4>
        </div>
      </>
    );
  }

  const indexOfLastDocument = Math.min(currentPage * documentsPerPage, documents.length);
  const indexOfFirstDocument = Math.max(indexOfLastDocument - documentsPerPage, 0);

  const currentDocuments = documents.slice(indexOfFirstDocument, indexOfLastDocument);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="container-fluid bg-primary mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '400px' }}>
          <h3 className="display-3 font-weight-bold text-white">Les niveaux</h3>
          <div className="d-inline-flex text-white">
            <p className="m-0"><Link className="text-white" to="/">Accueil</Link></p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Niveaux</p>
          </div>
        </div>
      </div>
      <div style={{ padding: '0 30px' }}>
        <div className="row">
          {currentDocuments.map((documentItem) => (
            <div className="col-lg-3 mb-4" key={documentItem._id}>
              <div className="card border-0 bg-light shadow-sm pb-2">
              <img className="card-img-top mb-2" src={documentItem.imagePath} alt={documentItem.title}  style={{ margin: '50px',width:"200px", height:"250px" }} />
                <div className="card-body text-center">
                  <h4 className="card-title">{documentItem.title}</h4>
                </div>
                <div className="flex justify-center">
                  <a
                    href={`/documents/${documentItem.filePath}`}
                    download={documentItem.filePath}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="btn btn-primary">
                      Télécharger
                    </button>
                  </a>
                </div>

              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center">
          {Array.from({ length: Math.ceil(documents.length / documentsPerPage) }).map((_, index) => (
            <button
              key={index}
              className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-secondary'} mx-1`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default DocumentsByLevelAndClassPage;
