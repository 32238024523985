import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "./Admin/Sidebar"; // Import the Sidebar component
import ConfirmationPopup from "./Admin/ConfirmationPopup";

const DocumentListWithSidebar = ({ onEdit, onDelete }) => {
  const [documents, setDocuments] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [loading, setLoading] = useState(true); // New state to handle loading
  const history = useHistory();

  useEffect(() => {
    // Fetch documents from your server here
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/documents`)
      .then((response) => {
        setDocuments(response.data);
        setLoading(false); // Set loading to false when documents are fetched
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, []);

  const handleDelete = async (documentId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/documents/${documentId}`
      );
      setDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc._id !== documentId)
      );
      alert("Document deleted successfully!🎉🧹");
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleEdit = (document) => {
    history.push(`/edit-document/${document._id}`);
  };

  const openDeleteModal = (document) => {
    setDocumentToDelete(document);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDocumentToDelete(null);
  };

  return (
    <div className="flex mt-0">
      <Sidebar />
      <div className="flex-1 p-6">
        <div className="mb-4">
          <h2 className="text-2xl font-semibold">Documents</h2>
        </div>
        <div className="mb-4 flex justify-end">
          <Link to="/admin/create-document">
            <button className="bg-[#138496] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Add New Document
            </button>
          </Link>
        </div>

        {/* Loading Spinner */}
        {loading ? (
          <div className="flex justify-center items-center w-full h-96">
            <div className="animate-spin border-4 border-t-4 border-[#138496] border-solid rounded-full w-16 h-16"></div>
          </div>
        ) : (
          <div className="overflow-x-auto w-full">
            {!loading && documents.length === 0 ? (
              <div className="text-black text-xl font-semibold text-center flex justify-center">
                No document found.
              </div>
            ) : (
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wider">
                      Title
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wider">
                      File Path
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((document) => (
                    <tr key={document._id}>
                      <td className="border-t px-6 py-4">{document.title}</td>
                      <td className="border-t px-6 py-4">
                        {document.filePath}
                      </td>
                      <td className="border-t px-6 py-4">
                        {/* <button
                          className="bg-[#138496] hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                          onClick={() => handleEdit(document)}
                        >
                          Edit
                        </button> */}
                        <button
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                          onClick={() => openDeleteModal(document)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {isDeleteModalOpen && (
        <ConfirmationPopup
          onConfirm={() => handleDelete(documentToDelete._id)}
          onCancel={closeDeleteModal}
        />
      )}
    </div>
  );
};

export default DocumentListWithSidebar;
