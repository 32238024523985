import React from "react";
import Sidebar from "../../components/Admin/Sidebar";

const AdminLevelsPage = () => {
  return (
    <div className="flex m-0">
      <Sidebar />
      <h5
        className="display-5 text-[35px] font-weight-bold mb-9 p-20"
        style={{ color: "#17a2b8", whiteSpace: "nowrap" }}
      >
        Page Under Construction..
      </h5>
    </div>
  );
};

export default AdminLevelsPage;
