import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LevelsComponent from '../components/LevelsComponent';

// Import class images
import classImage1 from "../img/class-1.jpg";
import classImage2 from "../img/class-2.jpg";
import classImage3 from "../img/class-3.jpg";

const LevelPrimairePage = () => {
    // Array of class image paths
    const classImages = [classImage1, classImage2, classImage3, classImage1, classImage2, classImage3];

    const history = useHistory();
    const [levelName, setLevelName] = useState('');
    const [className, setClassName] = useState('');

    const handleChooseClick = (index) => {
        // Example
        const level = 1;
        const docClass = index + 1;
        const newLevelName = 'Primaire';
        const newClassName = `${index === 0 ? '1ère' : `${index + 1}ème`} année primaire`;
    
        setLevelName(newLevelName);
        setClassName(newClassName);

        const path = `/documentsByLevelAndClasse/${level}-${docClass}`;
        history.push(path, { levelName: newLevelName, className: newClassName });
    };

    return (
        <>
            <div className="container-fluid bg-primary mb-5">
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: '400px' }}>
                    <h3 className="display-3 font-weight-bold text-white">Primaire</h3>
                    <div className="d-inline-flex text-white">
                        <p className="m-0"><Link className="text-white" to="/">Accueil</Link></p>
                        <p className="m-0 px-2">/</p>
                        <p className="m-0">Primaire</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {classImages.map((image, index) => (
                        <div key={index} className="col-md-4 mb-4">
                            <div className="card">
                                <img src={image} className="card-img-top" alt={`Class ${index + 1}`} />
                                <div className="card-body">
                                    <h5 className="card-title text-center mb-0">{`${index === 0 ? '1ère' : `${index + 1}ème`} année primaire`}</h5>
                                    {/* Add other details as needed */}
                                </div>
                                <button className="btn btn-primary px-4 mx-auto mb-4" onClick={() => { handleChooseClick(index) }}>Choisir</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default LevelPrimairePage;
