import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const DocumentsTestPage = () => {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [documentsPerPage] = useState(8);

  useEffect(() => {
    // Fetch data from your backend API when the component mounts
    fetch(`${process.env.REACT_APP_SERVER_URL}/documents/by-level-and-class/2`)
      .then((response) => response.json())
      .then((data) => {
        setDocuments(data); // Update state with the real data
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []); // The empty dependency array ensures this effect runs only once

//   // Calculate the indexes of documents to display on the current page
//   const indexOfLastDocument = currentPage * documentsPerPage;
//   const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
//   const currentDocuments = documents.slice(indexOfFirstDocument, indexOfLastDocument);

//   // Function to change the current page
//   const paginate = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

  return (
    <>
      {/* Header Start */}
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: '400px' }}
        >
          <h3 className="display-3 font-weight-bold text-white">Les niveaux</h3>
          <div className="d-inline-flex text-white">
            <p className="m-0"><a className="text-white" href="/">Accueil</a></p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Niveaux</p>
          </div>
        </div>
      </div>
      {/* Header End */}
      <div style={{ padding: '0 30px' }}>

        <div className="row">
          {documents.map((documentItem) => (
            <div className="col-lg-3 mb-4" key={documentItem._id}>
              {/* Render class details using real data */}
              <div className="card border-0 bg-light shadow-sm pb-2">
                <img className="card-img-top mb-2" src={documentItem.imageUrl} alt={documentItem.title} />
                <div className="card-body text-center">
                  <h4 className="card-title">{documentItem.title}</h4>
                </div>
                {/* Use Link to navigate to the document detail page */}
                <Link to={`/documents/${documentItem._id}`} className="btn btn-primary px-4 mx-auto mb-4">
                  Voir
                </Link>
                
              </div>
            </div>
          ))}
        </div>
        {/* Pagination
        <div className="d-flex justify-content-center">
          {Array.from({ length: Math.ceil(documents.length / documentsPerPage) }).map((_, index) => (
            <button
              key={index}
              className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-secondary'} mx-1`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div> */}
      </div>
      {/* ... Other parts of your component */}
    </>
  );
};

export default DocumentsTestPage;
