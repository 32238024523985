import React from "react";

const Snackbar = ({ message, type, visible }) => {
  if (!visible) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: type === "success" ? "green" : "red",
        color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        fontSize: "16px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
      }}
    >
      {message}
    </div>
  );
};

export default Snackbar;
