import React from 'react';
import blogImage1 from "../img/blog-1.jpg";
import blogImage2 from "../img/blog-2.jpg";
import blogImage3 from "../img/blog-3.jpg";
const BlogPage = () => {
  return (
    <>
      {/* Header Start */}
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: '400px' }}
        >
          <h3 className="display-3 font-weight-bold text-white">Blog</h3>
          <div className="d-inline-flex text-white">
            <p className="m-0"><a className="text-white" href="">Accueil</a></p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Blog</p>
          </div>
        </div>
      </div>
      {/* Header End */}
      {/* Blog Start */}
      <div className="container-fluid pt-5">
        <div className="container">
          <div className="text-center pb-2">
            <p className="section-title px-5">
              <span className="px-2">Blog</span>
            </p>
            <h1 className="mb-4">Nos derniers articles</h1>
          </div>
          <div className="row pb-3">
            <div className="col-lg-4 mb-4">
              <div className="card border-0 shadow-sm mb-2">
                <img className="card-img-top mb-2" src={blogImage1} alt="" />
                <div className="card-body bg-light text-center p-4">
                  <h5 className="">Réalité Virtuelle et Éducation en Ligne</h5>
                  <div className="d-flex justify-content-center mb-3">
                    <small className="mr-3"><i className="fa fa-user text-primary"></i> Attia Neyrouz</small>
                    <small className="mr-3"><i className="fa fa-folder text-primary"></i> Professeur</small>
                    <small className="mr-3"><i className="fa fa-comments text-primary"></i> 27 </small>
                  </div>
                  <p>
                    Réalité Virtuelle et Éducation en Ligne : Une fusion innovante qui redéfinit l'expérience éducative. L'évolution rapide de la réalité...
                  </p>
                  <a href="/article3" className="btn btn-primary px-4 mx-auto my-2">Voir plus</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card border-0 shadow-sm mb-2">
                <img className="card-img-top mb-2" src={blogImage2} alt="" />
                <div className="card-body bg-light text-center p-4">
                  <h5 className="">La Révolution de l'Apprentissage des Langues en Ligne</h5>
                  <div className="d-flex justify-content-center mb-3">
                    <small className="mr-3"><i className="fa fa-user text-primary"></i> Attia Neyrouz </small>
                    <small className="mr-3"><i className="fa fa-folder text-primary"></i> Professeur</small>
                    <small className="mr-3"><i className="fa fa-comments text-primary"></i> 15</small>
                  </div>
                  <p>
                    La Révolution de l'Apprentissage des Langues en Ligne ouvre de nouvelles portes passionnantes pour ceux qui aspirent...                  </p>
                  <a href="/article2" className="btn btn-primary px-4 mx-auto my-2">Voir plus</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card border-0 shadow-sm mb-2">
                <img className="card-img-top mb-2" src={blogImage3} alt="" />
                <div className="card-body bg-light text-center p-4">
                  <h5 className=""> Réussir le bac avec des simples astuces</h5>
                  <div className="d-flex justify-content-center mb-3">
                    <small className="mr-3"><i className="fa fa-user text-primary"></i> Attia Neyrouz </small>
                    <small className="mr-3"><i className="fa fa-folder text-primary"></i> Professeur </small>
                    <small className="mr-3"><i className="fa fa-comments text-primary"></i> 45</small>
                  </div>
                  <p>
                    Le Baccalauréat en Tunisie est un moment crucial dans la vie des étudiants, ouvrant la porte à de nombreuses opportunités académiques et professionnelles...
                  </p>
                  <a href="/article1" className="btn btn-primary px-4 mx-auto my-2">Voir plus</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}
    </>
  )
}

export default BlogPage;