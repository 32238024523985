import React from 'react';
import classImage1 from "../img/class-1.jpg";
import classImage2 from "../img/class-2.jpg";
import classImage3 from "../img/class-3.jpg";
import LevelsComponent from '../components/LevelsComponent';
const LevelsPage = () => {
    return (
        <>
        <div className="container-fluid bg-primary mb-5 p-0">
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: '400px', padding: '0' }}>
            <h3 className="display-3 font-weight-bold text-white">Les niveaux</h3>
            <div className="d-inline-flex text-white">
              <p className="m-0"><a className="text-white" href="/">Accueil</a></p>
              <p className="m-0 px-2">/</p>
              <p className="m-0">Niveaux</p>
            </div>
          </div>
        </div>
        <LevelsComponent />
      </>
      
    )
}
export default LevelsPage;