// DocumentForm.js
import React, { useState } from "react";
import teamImage1 from "../img/teacher3.png";
import teamImage2 from "../img/teacher1.svg";
import teamImage3 from "../img/teacher2.svg";
import teamImage4 from "../img/teacher4.svg";
const TeamComponent = () => {
  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="text-center pb-2">
          <p className="section-title px-5">
            <span className="px-2">Nos enseignants</span>
          </p>
          <h1 className="mb-4">Rencontrez notre équipe</h1>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 text-center team mb-5">
            <div
              className="position-relative overflow-hidden mb-4"
              style={{ borderRadius: "100%" }}
            >
              <img className="img-fluid w-100" src={teamImage1} alt="" />
              <div className="team-social d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <h4>Kamel</h4>
            <i>Enseignant de Mathématiques</i>
          </div>
          <div className="col-md-6 col-lg-3 text-center team mb-5">
            <div
              className="position-relative overflow-hidden mb-4"
              style={{ borderRadius: "100%" }}
            >
              <img className="img-fluid w-100" src={teamImage2} alt="" />
              <div className="team-social d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <h4>Neyrouz</h4>
            <i>Enseignante universitaire</i>
          </div>
          <div className="col-md-6 col-lg-3 text-center team mb-5">
            <div
              className="position-relative overflow-hidden mb-4"
              style={{ borderRadius: "100%" }}
            >
              <img className="img-fluid w-100" src={teamImage3} alt="" />
              <div className="team-social d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <h4>Firas</h4>
            <i>Enseignant D'Anglais</i>
          </div>
          <div className="col-md-6 col-lg-3 text-center team mb-5">
            <div
              className="position-relative overflow-hidden mb-4"
              style={{ borderRadius: "100%" }}
            >
              <img className="img-fluid w-100" src={teamImage4} alt="" />
              <div className="team-social d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center mr-2 px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-outline-light text-center px-0"
                  style={{ width: "38px", height: "38px" }}
                  href="#"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <h4>Ahlem</h4>
            <i>Ingénieur Informatique</i>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamComponent;
