import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Get the token from localStorage (you can also use sessionStorage depending on your preference)
  const token = localStorage.getItem('token');
  const email = localStorage.getItem('email'); 
  
  // Check if the token exists and the email is 'neydigiclass'
  const isAuthenticated = token && email === 'neydigiclass';
 
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
