import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "../../components/Snackbar";
import axios from "axios";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbar, setSnackbar] = useState({
    message: "",
    type: "",
    visible: false,
  });
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/admin/documents");
    }
  }, [history]);

  const showSnackbar = (message, type) => {
    setSnackbar({ message, type, visible: true });
    setTimeout(() => {
      setSnackbar((prev) => ({ ...prev, visible: false }));
    }, 3000);
  };

  const handleLogin = async () => {
    try {
      const loginUrl = `${process.env.REACT_APP_SERVER_URL}/users/login`;
      console.log(loginUrl);
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("email", email);
        showSnackbar("Login successfully!", "success");
        history.push("/admin/documents");
      } else {
        showSnackbar("Incorrect credentials! Please try again.", "error");
      }
    } catch (err) {
      showSnackbar("An error occurred. Please try again.", "error");
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Admin Login</h1>
      <div style={styles.inputContainer}>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={styles.input}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
      </div>
      <button
        onClick={handleLogin}
        style={{ ...styles.button, backgroundColor: "#17a2b8" }}
      >
        Login Admin
      </button>

      {/* Snackbar */}
      <Snackbar
        message={snackbar.message}
        type={snackbar.type}
        visible={snackbar.visible}
      />
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    margin: "auto",
    width: "50%",
    marginTop: "30px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  input: {
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
  },
  button: {
    padding: "10px 20px",
    background: "#0074d9",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "18px",
  },
};

export default LoginPage;
