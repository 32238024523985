import React, { useState, useEffect } from "react";

const InscriptionComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    selectedClass: "",
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // TODO send data to digi class email
    // Show the popup
    setShowPopup(true);
  };

  useEffect(() => {
    if (showPopup) {
      // You can customize the content of your popup here
      alert(
        "Merci ! Votre réservation a été effectuée avec succès. Nous vous enverrons un e-mail avec les détails."
      );
      // Reset the form data
      setFormData({
        name: "",
        email: "",
        selectedClass: "",
      });
      // Hide the popup after showing it
      setShowPopup(false);
    }
  }, [showPopup]);

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 mb-5 mb-lg-0">
            <p className="section-title pr-5">
              <span className="pr-2">Réservez une place</span>
            </p>
            <h1 className="mb-4">Réservez une place pour votre enfant</h1>
            <p>
              Nous proposons des cours en ligne de haute qualité que vous pouvez
              essayer avec une session gratuite. N'hésitez pas à réserver votre
              place dès maintenant !
            </p>
            <ul className="list-inline m-0">
              <li className="py-2">
                <i className="fa fa-check text-success mr-3"></i>Français
              </li>
              <li className="py-2">
                <i className="fa fa-check text-success mr-3"></i>Anglais
              </li>
              <li className="py-2">
                <i className="fa fa-check text-success mr-3"></i>Arabe
              </li>
              <li className="py-2">
                <i className="fa fa-check text-success mr-3"></i>Mathématiques
              </li>
              <li className="py-2">
                <i className="fa fa-check text-success mr-3"></i>Gestion et
                Économie
              </li>
              <li className="py-2">
                <i className="fa fa-check text-success mr-3"></i>Informatique
              </li>
            </ul>
            <a href="" className="btn btn-primary mt-4 py-2 px-4">
              Réservez maintenant
            </a>
          </div>
          <div className="col-lg-5">
            <div className="card border-0">
              <div className="card-header bg-secondary text-center p-4">
                <h1 className="text-white m-0">Réservez une place</h1>
              </div>
              <div className="card-body rounded-bottom bg-primary p-5">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border-0 p-4"
                      placeholder="Votre nom"
                      required="required"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control border-0 p-4"
                      placeholder="Votre adresse e-mail"
                      required="required"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <select
                      className="custom-select border-0 px-4"
                      style={{ height: "47px" }}
                      name="selectedClass"
                      value={formData.selectedClass}
                      onChange={handleInputChange}
                    >
                      <option selected>Sélectionnez une classe</option>
                      <option value="1">5ème année primaire</option>
                      <option value="2">6ème année primaire</option>
                      <option value="3">7ème année de base</option>
                      <option value="4">8ème année de base</option>
                      <option value="5">9ème année de base</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <select
                      className="custom-select border-0 px-4"
                      style={{ height: "47px" }}
                      name="selectedMatiere"
                      value={formData.selectedMatiere}
                      onChange={handleInputChange}
                    >
                      <option selected>Sélectionnez la matière</option>
                      <option value="fr">Français</option>
                      <option value="ang">Anglais</option>
                      <option value="arabe">Arabe</option>
                      <option value="math">Mathématiques</option>
                      <option value="ge">Gestion et Économie</option>
                      <option value="info">Informatique</option>
                    </select>
                  </div>
                  <div>
                    <button
                      className="btn btn-secondary btn-block border-0 py-3"
                      type="submit"
                      onClick={handleFormSubmit}
                    >
                      Réserver
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InscriptionComponent;
