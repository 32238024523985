import React from 'react';
import classImage1 from "../img/class-1.jpg";
import classImage2 from "../img/class-2.jpg";
import classImage3 from "../img/class-3.jpg";

const LevelsComponent = () => {
  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="text-center pb-2">
          <p className="section-title px-5">
            <span className="px-2">Niveaux</span>
          </p>
          <h1 className="mb-4">Choisir votre niveau</h1>
        </div>
        <div className="row">
          <div className="col-lg-3 mb-5">
            <div className="card border-0 bg-light shadow-sm pb-2">
              <img className="card-img-top mb-2" src={classImage1} alt="" />
              <div className="card-body text-center">
                <h4 className="card-title">Primaire</h4>
              </div>
              <a href="/primaire" className="btn btn-primary px-4 mx-auto mb-4">Choisir</a>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="card border-0 bg-light shadow-sm pb-2">
              <img className="card-img-top mb-2" src={classImage2} alt="" />
              <div className="card-body text-center">
                <h4 className="card-title">De base</h4>
              </div>
              <a href="/base" className="btn btn-primary px-4 mx-auto mb-4">Choisir</a>
            </div>
          </div>
          <div className="col-lg-3 mb-5">
            <div className="card border-0 bg-light shadow-sm pb-2">
              <img className="card-img-top mb-2" src={classImage3} alt="" />
              <div className="card-body text-center">
                <h4 className="card-title">Secondaire</h4>
              </div>
              <a href="/secondaire" className="btn btn-primary px-4 mx-auto mb-4">Choisir</a>
            </div>
          </div>
          {/* Add the fourth column here */}
          <div className="col-lg-3 mb-5">
            <div className="card border-0 bg-light shadow-sm pb-2">
              {/* Replace classImage4 with the appropriate image */}
              <img className="card-img-top mb-2" src={classImage1} alt="" />
              <div className="card-body text-center">
                <h4 className="card-title">Bac</h4>
              </div>
              <a href="/bac" className="btn btn-primary px-4 mx-auto mb-4">Choisir</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelsComponent;
