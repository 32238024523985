import React from "react";
import header from "../img/header.png";
import Footer from "./Footer";

function UnderDevelopmentPage() {
  return (
    <div>
      {/* Header */}
      <div className="container-fluid  px-0 px-md-5 mb-0">
        <div className="row align-items-center px-3">
          <div className="col-md-6">
            <h4 className="text-white  mb-10 mt-10 mt-lg-9">DIGI-CLASS</h4>
            <h5
              className="display-5 text-[35px] font-weight-bold mb-9"
              style={{ color: "#17a2b8", whiteSpace: "nowrap" }}
            >
              Coming Soon...
            </h5>
          </div>
        </div>
      </div>
      {/* End of Header */}
    </div>
  );
}

export default UnderDevelopmentPage;
