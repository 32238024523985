import React from "react";
import OwlCarousel from "react-owl-carousel";
import header from "../img/header.png";
import testimonialImage1 from "../img/testimonial-1.jpg";
import testimonialImage2 from "../img/testimonial-2.jpg";
import testimonialImage3 from "../img/testimonial-3.jpg";
import testimonialImage4 from "../img/testimonial-4.jpg";
import aboutImage1 from "../img/about-1.jpg";
import aboutImage2 from "../img/about-2.jpg";

//import inscriptionImage from "../img/inscription.jpg";

import blogImage1 from "../img/blog-1.jpg";
import blogImage2 from "../img/blog-2.jpg";
import blogImage3 from "../img/blog-3.jpg";
import Footer from "./Footer";
import TeamComponent from "../components/TeamComponent";
import InscriptionComponent from "../components/InscriptionComponent";
import LevelsComponent from "../components/LevelsComponent";
import CaracsComponent from "../components/CaracsComponent";
function HomePage() {
  const testimonialItems = [
    {
      quote:
        "Je suis vraiment impressionné par Digi Class. Leurs cours en ligne sont tout simplement parfaits, et leurs fichiers sont d'une grande qualité.",
      name: "Parent Name",
      profession: "Profession",
      imageSrc: testimonialImage1,
    },
    {
      quote:
        "Digi Class, c'est une révolution. Des cours impeccables et des fichiers inestimables.",
      name: "Parent Name",
      profession: "Profession",
      imageSrc: testimonialImage2,
    },
    {
      quote:
        "Je ne me lasse pas de Digi Class. Des cours impeccables et des fichiers précieux.",
      name: "Parent Name",
      profession: "Profession",
      imageSrc: testimonialImage3,
    },
    {
      quote:
        "Je suis tombé sur Digi Class par hasard, et je suis tellement reconnaissant. Les cours et les fichiers sont d'une grande aide",
      name: "Parent Name",
      profession: "Profession",
      imageSrc: testimonialImage4,
    },
  ];

  const owlCarouselOptions = {
    loop: true,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  };
  return (
    <div>
      {/* Header */}
      <div className="container-fluid bg-primary px-0 px-md-5 mb-5">
        <div className="row align-items-center px-3">
          <div className="col-lg-6 text-center text-lg-left">
            <h4 className="text-white mb-4 mt-5 mt-lg-0">DIGI-CLASS</h4>
            <h1 className="display-3 font-weight-bold text-white">
              1er site d'enseignement en ligne en Tunisie
            </h1>
            <h2 className="text-white mb-4">
              Une nouvelle approche de l'éducation pour les enfants
            </h2>
            <a href="/niveaux" className="btn btn-secondary mt-1 py-3 px-5">
              Nos formations
            </a>
          </div>
          <div className="col-lg-6 text-center text-lg-right">
            <img className="img-fluid mt-5" src={header} alt="" />
          </div>
        </div>
      </div>
      {/* End of Header */}

      {/* Facilities Start */}

      {/* <CaracsComponent/> */}

      {/* Facilities End */}

      {/* About Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={aboutImage1}
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <p className="section-title pr-5">
                <span className="pr-2">Que proposons-nous?</span>
              </p>
              <h1 className="mb-4">
                Votre chemin vers la réussite passe par nous.
              </h1>
              <p>
                Nous mettons à votre disposition des fichiers, des cours, des
                tests ainsi que des devoirs accompagnés de corrections qui vous
                guideront vers la réussite.
              </p>
              <div className="row pt-2 pb-4">
                <div className="col-6 col-md-4">
                  <img className="img-fluid rounded" src={aboutImage2} alt="" />
                </div>
                <div className="col-6 col-md-8">
                  <ul className="list-inline m-0">
                    <li className="py-2 border-top border-bottom">
                      <i className="fa fa-check text-primary mr-3"></i>
                      Cours, exercices et devoirs gratuits
                    </li>
                    <li className="py-2 border-bottom">
                      <i className="fa fa-check text-primary mr-3"></i>Pour tous
                      les niveaux
                    </li>
                    <li className="py-2 border-bottom">
                      <i className="fa fa-check text-primary mr-3"></i>Avec les
                      corrigés
                    </li>
                  </ul>
                </div>
              </div>
              <a href="" className="btn btn-primary mt-2 py-2 px-4">
                Les niveaux
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Class Start */}
      <LevelsComponent />
      {/* Class End */}

      {/* Inscription Start */}
      <InscriptionComponent />
      {/* Inscription End */}

      {/* Team Start */}
      <TeamComponent />
      {/* Team End */}

      {/* Testimonial Start */}
      {/* <div className="container-fluid py-5">
        <div className="container p-0">
          <div className="text-center pb-2">
            <p className="section-title px-5">
              <span className="px-2">Témoignages</span>
            </p>
            <h1 className="mb-4">Ce que disent les parents !</h1>
          </div>
          <div className="owl-carousel testimonial-carousel">
            <div className="testimonial-item px-3">
              <div className="bg-light shadow-sm rounded mb-4 p-4">
                <h3 className="fas fa-quote-left text-primary mr-3"></h3>
                Je suis vraiment impressionné par Digi Class. Leurs cours en ligne sont tout simplement parfaits, et leurs fichiers sont d'une grande qualité.
              </div>

            </div>
            <div className="testimonial-item px-3">
              <div className="bg-light shadow-sm rounded mb-4 p-4">
                <h3 className="fas fa-quote-left text-primary mr-3"></h3>
                Digi Class, c'est une révolution. Des cours impeccables et des fichiers inestimables.
              </div>

            </div>
            <div className="testimonial-item px-3">
              <div className="bg-light shadow-sm rounded mb-4 p-4">
                <h3 className="fas fa-quote-left text-primary mr-3"></h3>
                Je ne me lasse pas de Digi Class. Des cours impeccables et des fichiers précieux.
              </div>

            </div>
            <div className="testimonial-item px-3">
              <div className="bg-light shadow-sm rounded mb-4 p-4">
                <h3 className="fas fa-quote-left text-primary mr-3"></h3>
                Je suis tombé sur Digi Class par hasard, et je suis tellement reconnaissant. Les cours et les fichiers sont d'une grande aide.
              </div>

            </div>
          </div>
        </div>
      </div> */}
      {/* Testimonial End */}

      {/* Blog Start */}
      <div className="container-fluid pt-5">
        <div className="container">
          <div className="text-center pb-2">
            <p className="section-title px-5">
              <span className="px-2">Blog</span>
            </p>
            <h1 className="mb-4">Nos derniers articles</h1>
          </div>
          <div className="row pb-3">
            <div className="col-lg-4 mb-4">
              <div className="card border-0 shadow-sm mb-2">
                <img className="card-img-top mb-2" src={blogImage1} alt="" />
                <div className="card-body bg-light text-center p-4">
                  <h5 className="">Réalité Virtuelle et Éducation en Ligne</h5>
                  <div className="d-flex justify-content-center mb-3">
                    <small className="mr-3">
                      <i className="fa fa-user text-primary"></i> Attia Neyrouz
                    </small>
                    <small className="mr-3">
                      <i className="fa fa-folder text-primary"></i> Professeur
                    </small>
                    <small className="mr-3">
                      <i className="fa fa-comments text-primary"></i> 27{" "}
                    </small>
                  </div>
                  <p>
                    L'éducation en ligne a franchi une nouvelle étape avec
                    l'introduction de la réalité virtuelle (RV) dans les salles
                    de classe numériques
                  </p>
                  <a
                    href="/article3"
                    className="btn btn-primary px-4 mx-auto my-2"
                  >
                    Voir plus
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card border-0 shadow-sm mb-2">
                <img className="card-img-top mb-2" src={blogImage2} alt="" />
                <div className="card-body bg-light text-center p-4">
                  <h5 className="">
                    La Révolution de l'Apprentissage des Langues en Ligne
                  </h5>
                  <div className="d-flex justify-content-center mb-3">
                    <small className="mr-3">
                      <i className="fa fa-user text-primary"></i> Attia Neyrouz{" "}
                    </small>
                    <small className="mr-3">
                      <i className="fa fa-folder text-primary"></i> Professeur
                    </small>
                    <small className="mr-3">
                      <i className="fa fa-comments text-primary"></i> 15
                    </small>
                  </div>
                  <p>
                    L'apprentissage des langues en ligne connaît une
                    transformation radicale, permettant aux apprenants d'accéder
                    à une multitude de langues et de ...
                  </p>
                  <a
                    href="/article2"
                    className="btn btn-primary px-4 mx-auto my-2"
                  >
                    Voir plus
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card border-0 shadow-sm mb-2">
                <img className="card-img-top mb-2" src={blogImage3} alt="" />
                <div className="card-body bg-light text-center p-4">
                  <h5 className=""> Réussir le bac avec des simples astuces</h5>
                  <div className="d-flex justify-content-center mb-3">
                    <small className="mr-3">
                      <i className="fa fa-user text-primary"></i> Attia Neyrouz{" "}
                    </small>
                    <small className="mr-3">
                      <i className="fa fa-folder text-primary"></i> Professeur{" "}
                    </small>
                    <small className="mr-3">
                      <i className="fa fa-comments text-primary"></i> 45
                    </small>
                  </div>
                  <p>
                    Le Baccalauréat en Tunisie est un moment crucial dans la vie
                    des étudiants, ouvrant la porte à de nombreuses opportunités
                    académiques et professionnelles...
                  </p>
                  <a
                    href="article1"
                    className="btn btn-primary px-4 mx-auto my-2"
                  >
                    Voir plus
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blog End */}
    </div>
  );
}

export default HomePage;
