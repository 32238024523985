// EditDocumentPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EditDocumentForm from '../components/EditDocumentForm';

const EditDocumentPage = () => {
  const { id } = useParams(); // Get the document ID from the URL parameters

  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    // Fetch the document data based on the document ID
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/documents/${id}`)
      .then((response) => {
        setDocumentData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching document data:', error);
      });
  }, [id]); // Include id in the dependency array to re-fetch data when it changes

  return (
    <div>
      <h2>Edit Document</h2>
      {documentData && <EditDocumentForm documentData={documentData} />}
    </div>
  );
};

export default EditDocumentPage;
