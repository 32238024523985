import React from 'react';

const ContactPage = () => {
  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="text-center pb-2">
          <p className="section-title px-5">
            <span className="px-2">Contactez-nous</span>
          </p>
          <h1 className="mb-4">Contactez-nous pour toute question</h1>
        </div>
        <div className="row">
          <div className="col-lg-7 mb-5">
            <div className="contact-form">
              <div id="success"></div>
              <form name="sentMessage" id="contactForm" noValidate>
                <div className="control-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Votre nom"
                    required
                    data-validation-required-message="Veuillez entrer votre nom"
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="control-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Votre adresse e-mail"
                    required
                    data-validation-required-message="Veuillez entrer votre adresse e-mail"
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="control-group">
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    placeholder="Sujet"
                    required
                    data-validation-required-message="Veuillez entrer un sujet"
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="control-group">
                  <textarea
                    className="form-control"
                    rows="6"
                    id="message"
                    placeholder="Message"
                    required
                    data-validation-required-message="Veuillez entrer votre message"
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div>
                  <button
                    className="btn btn-primary py-2 px-4"
                    type="submit"
                    id="sendMessageButton"
                  >
                    Envoyer le message
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5 mb-5">
            <div className="d-flex">
              <i className="fa fa-map-marker-alt d-inline-flex align-items-center justify-content-center bg-primary text-secondary rounded-circle" style={{ width: '45px', height: '45px' }}></i>
              <div className="pl-3">
                <h5>Adresse</h5>
                <p>Tunisia</p>
              </div>
            </div>
            <div className="d-flex">
              <i className="fa fa-envelope d-inline-flex align-items-center justify-content-center bg-primary text-secondary rounded-circle" style={{ width: '45px', height: '45px' }}></i>
              <div className="pl-3">
                <h5>E-mail</h5>
                <p>digiclasseducation@gmail.com</p>
              </div>
            </div>
            <div className="d-flex">
              <i className="fa fa-phone-alt d-inline-flex align-items-center justify-content-center bg-primary text-secondary rounded-circle" style={{ width: '45px', height: '45px' }}></i>
              <div className="pl-3">
                <h5>Téléphone</h5>
                <p>+216 48 028 900</p>
              </div>
            </div>
            <div className="d-flex">
              <i className="far fa-clock d-inline-flex align-items-center justify-content-center bg-primary text-secondary rounded-circle" style={{ width: '45px', height: '45px' }}></i>
              <div className="pl-3">
                <h5>Heures d'ouverture</h5>
                <strong>Tous les jours:</strong>
                <p className="m-0">24 / 24</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
