import React, { useState } from 'react';
import axios from 'axios'; // Don't forget to import axios
import { useHistory } from 'react-router-dom';

const EditDocumentForm = ({ documentData }) => {
  const [formData, setFormData] = useState({
    title: documentData.title,
    filePath: documentData.filePath,
    metaTitle: documentData.metaTitle,
    // Add other form fields here and initialize them with the corresponding values from documentData
  });
  const [isSuccess, setIsSuccess] = useState(false); // State to track success
  const history = useHistory(); // Get the history object

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send an Axios PUT request to update the document on the backend
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/documents/${documentData._id}`,
        formData
      );

      // Handle success, e.g., show a success message or navigate back to the "Documents" page
      console.log('Document updated:', response.data);
      setIsSuccess(true);
       history.push('/documents');
    } catch (error) {
      // Handle error, e.g., show an error message to the user
      console.error('Error updating document:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 border rounded-md shadow-md">
      <h2 className="text-xl font-semibold mb-4">Edit Document</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title:
          </label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="filePath" className="block text-sm font-medium text-gray-700">
            File Path:
          </label>
          <input
            type="text"
            name="filePath"
            value={formData.filePath}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="metaTitle" className="block text-sm font-medium text-gray-700">
            Meta Title:
          </label>
          <input
            type="text"
            name="metaTitle"
            value={formData.metaTitle}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>

        {/* Add more form fields here following the same pattern */}

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditDocumentForm;
